import React from 'react';
import Layout from '@/components/layout';
import Lframe from '@/components/Lframe/Lframe';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import Metadata from '@/components/Metadata/Metadata';
import FeatureTiles from '@/components/FeatureTiles/FeatureTiles';
import PageData from '../data/pages/loans/calculators-and-tools.json';

import { COLOR } from '../utils/constants';

const CalculatorsAndToolsPage = props => (
  <Layout location={props.location}>
    <main className="navigation-spacer">
      <Metadata
        title={PageData.metaTitle}
        description={PageData.metaDesc}
        canonical="/calculators-and-tools/"
      />
      <Lframe calculators />

      <HeroBranded
        css={`
          background: ${COLOR.GREY6};
        `}
        title={<>Calculators and tools</>}
        text="Let's help you get started!"
      />

      <FeatureTiles
        id="calculators-and-tools"
        tiles={PageData.content.calculators}
      />
    </main>
  </Layout>
);

export default CalculatorsAndToolsPage;
